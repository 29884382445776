<!--
Edit form for PvSchema (used for currently existing pv)
-->
<i18n>
{
  "de": {
    "pvYear": "Baujahr",
    "remarksTitle": "Bemerkungen"
  }
}
</i18n>

<template>
  <EditContainer
    class="c-edit-pv"
    :bind-form-state="formState"
    :allow-edit="getBuildingPermission('EDIT_PV')"
    @container="onFormEvent"
  >
    <FormRow :label="$t('pvYear')">
      <NumericInput
        v-model="model.year"
        class="limit-width"
        noFormatting
        :optional="isPvSectionsEmpty"
        int
        :min="1900"
        :max="2100"
        :edit="isEditing"
        @validation="onValidation($event, 'pvYear')"
      />
      <EstimateInput v-model="model.year_estimated" :edit="isEditing" />
    </FormRow>

    <FormRow :label="$t('remarksTitle')">
      <TextInput v-model="model.remarks" :edit="isEditing" :allow-empty="true" />
    </FormRow>

    <EditPvSections
      v-model="model.sections"
      :pv-results="pvResults"
      :edit="isEditing"
      @input="onSectionsChange"
      @validation="validateSections"
    />
  </EditContainer>
</template>
<script>
import { mapActions } from 'vuex'

import ApiFormMixin from '@/components/shared/forms/ApiFormMixin.vue'

import EditContainer from '@/components/shared/forms/EditContainer.vue'
import EditPvSections from '@/components/building/edit/EditPvSections.vue'
import NumericInput from '@/components/shared/forms/NumericInput.vue'
import FormRow from '@/components/shared/forms/FormRow.vue'
import TextInput from '@/components/shared/forms/TextInput.vue'
import EstimateInput from '@/components/shared/forms/EstimateInput.vue'

export default {
  mixins: [
    ApiFormMixin,
    /* ApiFormMixin:
      - Requires implementation of the following methods: resetModel, saveHandler, isValid (optional)
      - Provides: model, isValid, isEditing, formState, onFormEvent, onValidation,
                  getBuildingPermission, getPortfolioPermission
    */
  ],

  components: {
    EditContainer,
    NumericInput,
    FormRow,
    TextInput,
    EstimateInput,
    EditPvSections,
  },

  props: {
    building: {
      type: Object,
      required: true,
    },
  },

  computed: {
    pvResults() {
      return this.building.initial_state.result && this.building.initial_state.result.pv.sections
    },

    isPvSectionsEmpty() {
      return this.model.sections.length === 0
    },
  },

  watch: {
    building: function () {
      this.resetModel()
    },
  },

  methods: {
    ...mapActions({
      updateBuilding: 'building/updateBuilding',
    }),

    validateSections(validation, field) {
      this.onValidation(validation, field)
    },

    onSectionsChange(sections) {
      this.model.sections = sections
    },

    resetModel() {
      this.model = {
        sections: [],
        year: this.building.initial_state.pv.year,
        year_estimated: this.building.initial_state.pv.year_estimated,
        remarks: this.building.initial_state.pv.remarks,
      }
      for (let i = 0; i < this.building.initial_state.pv.sections.length; i++) {
        const section = this.building.initial_state.pv.sections[i]
        this.model.sections.push({
          capacity: section.capacity,
          angle: section.angle,
          orientation: section.orientation,
          loss: section.loss,
          production_manual: section.production_manual,
          ownuse_ratio_manual: section.ownuse_ratio_manual,
        })
      }
    },

    async saveHandler() {
      await this.updateBuilding({
        id: this.building.id,
        initial_state: {
          scenario_id: this.building.initial_state.scenario_id,
          pv: this.model,
        },
      })
    },
  },
}
</script>

<style lang="scss">
.c-edit-pv {
  & .limit-width {
    max-width: 150px;
    display: inline-block;
  }
}
</style>
