<!--
TODO: Refactor and maybe break into multiple components
-->
<i18n>
{
  "de": {
    "buildingYearLabel": "Baujahr Gebäude",
    "buildingYearInfoBox": "Wenn dieses Feld leer gelassen wird, wird das durchschnittliche Baujahr aus den GWR-Daten übernommen (falls vorhanden).",
    "floorsNumberLabel": "Anzahl Geschosse",
    "floorsNumberInfoBox": "Wird für Berechnung der Flächen und Wärmeverluste verwendet. Wenn dieses Feld leer gelassen wird, wird die durchschnittliche Geschosszahl falls vorhanden aus den GWR-Daten übernommen mit folgender Logik: Bei 3 oder 4 Geschossen wird eines als unbeheizt angenommen, bei 5 oder mehr werden 2 als unbeheizt angenommen. (Die Angabe hier entspricht den beheizten Geschossen)",
    "roofTypeLabel": "Dach-Typ",
    "unknownData": "Unbekannt",
    "roofTypeFlat": "Flachdach",
    "roofTypeGable": "Schrägdach",
    "atticLabel": "Estrich",
    "atticHeatedLabel": "Estrich ist ausgebaut und beheizt",
    "atticNotHeatedLabel": "Estrich unbeheizt",
    "basementLabel": "Keller",
    "basementExistsLabel": "Gebäude ist unterkellert",
    "basementNotExistsLabel": "Gebäude ist nicht unterkellert",
    "wallsInsulationLabel": "Fassade",
    "windowsInsulationLabel": "Fenster",
    "basementInsulationLabel": "Kellerdecke",
    "roofInsulationLabel": "Dach",
    "atticInsulationLabel": "Oberste Geschossdecke",
    "typicalInsulation": "Benchmark Bauteilalter",
    "customInsulation": "Vorgabe U-Wert",
    "yearTitle": "Baujahr",
    "replacementYearTitle": "Planung",
    "replacementCostsTitle": "Kosten",
    "componentTitle": "Komponente",
    "areaTitle": "Fläche",
    "insulationStandardTitle": "Wärmedämmung",
    "uvalueTitle": "U-Wert",
    "qtTitle": "Wärmeverluste",
    "remarksTitle": "Bemerkungen",
    "hasHeatingLabel": "Heizwärmebedarf",
    "hasHeatingCheckLabel": "Gebäude beheizt",
    "hasNoHeatingCheckLabel": "Gebäude nicht beheizt",
    "envelopeModelLabel": "Wärmebedarf automatisch berechnen",
    "envelopeNoModelLabel": "Wärmebedarf manuell vorgeben",
    "manualRhDemandLabel": "Vorgabe Heizwärmebedarf",
    "roofTypeInfoBox": "Wird für die Berechnung der Wärmeverluste sowie für die Sanierungsplanung verwendet. Je nach Dachtyp werden unterschiedliche Sanierungskosten und Lebensdauern angenommen. Kann leer gelassen werden für eine automatische Bestimmung aus GIS-Daten",
    "componentYearInfoBoxTitle": "Baujahr oder letzte Sanierung von Bauteilen",
    "componentYearInfoBox": "Wird für die Massnahmenplanung und (falls keine genauen Angaben vorhanden) für die Einschätzung der Wärmedämmung verwendet.",
    "componentAreaInfoBoxTitle": "Bauteilfläche",
    "componentAreaInfoBox": "Wird für die Berechnung der Wärmeverluste sowie für die Schätzung von Sanierungskosten verwendet. Kann leer gelassen werden für eine automatische Schätzung auf Basis der Energiebezugsfläche und Geschosszahl.",
    "replacementCostsInfoBoxTitle": "Sanierungskosten aus Instandsetzungsplanung",
    "replacementCostsInfoBox": "Wenn dieses Feld leer gelassen wird, werden die Kosten anhand von flächenabhängigen Kennzahlen geschätzt.",
    "nextReplacementInfoBoxTitle": "Jahr nächster Eingriff aus Instandsetzungsplanung",
    "nextReplacementInfoBox": "Wenn dieses Feld leer gelassen wird, werden Standardlebensdauer pro Bauteil angenommen.",
    "qtInfoBoxTitle": "Modellierte Wärmeverluste",
    "qtInfoBox": "Modellierte Transmissionswärmeverluste dieses Bauteils, ohne Berücksichtigung der Kalibrierung.",
    "heritageProtection": "Denkmalschutz",
    "heritageProtectionType": "Inventar",
    "heritageProtectionOptions": {
      "UNKNOWN": "Unbekannt",
      "NONE": "Nein",
      "CANTON": "Kantonal",
      "REGION": "Regional"
    },
    "heritageProtectionRemarks": "Bemerkungen",
    "volumeLabel": "Gebäudevolumen",
    "componentStateEstimated": "Flag: Baujahr / letzte Sanierung unbekannt oder geschätzt",
    "componentYearDerived": "Baujahr von Gebäude abgeleitet: Bitte Baujahr prüfen.",
    "componentYearDerivedDue": "Baujahr von Gebäude abgeleitet: Die Komponente hat die Lebensdauer überschritten. Bitte Baujahr prüfen.",
    "missingRenovationStandardTooltip": "Der Renovationsstandard wurde zwischenzeitlich entfernt.",
    "withoutHeating": {
      "hasManualEnvelopeMeasures": {
        "title": "Heizwärmebedarf: Read-Only",
        "text": "(Read-Only) Es wurden manuelle Hüllen-Massnahmen definiert. Bitte löschen Sie diese, bevor Sie den Heizwärmebedarf ändern können."
      }
    }
  }
}
</i18n>

<template>
  <EditContainer
    :bind-form-state="formState"
    :allow-edit="getBuildingPermission('EDIT_ENVELOPE')"
    class="c-edit-envelope"
    @container="onFormEvent"
  >
    <!-- Remarks -->
    <FormRow v-if="isEditing || model.remarks" :label="$t('remarksTitle')">
      <TextInput v-model="model.remarks" :edit="isEditing" :allow-empty="true" />
    </FormRow>

    <!-- Heating demand -->
    <FormRow
      v-if="isEditing || model.without_heating || enable_manual_rh_demand"
      :label="$t('hasHeatingLabel')"
      :info-text="isEditing && hasManualEnvelopeMeasures ? $t('withoutHeating.hasManualEnvelopeMeasures.text') : ''"
    >
      <CheckInput
        v-model="model.without_heating"
        :edit="isEditing"
        :disabled="hasManualEnvelopeMeasures"
        :label="$t('hasNoHeatingCheckLabel')"
        :active-label="$t('hasNoHeatingCheckLabel')"
        :inactive-label="$t('hasHeatingCheckLabel')"
      />
      <template v-if="!model.without_heating">
        <CheckInput
          v-model="enable_manual_rh_demand"
          :edit="isEditing"
          :disabled="hasManualEnvelopeMeasures"
          :label="$t('envelopeNoModelLabel')"
          :active-label="$t('envelopeNoModelLabel')"
          :inactive-label="$t('envelopeModelLabel')"
        />
      </template>
    </FormRow>

    <!-- Manual RH Demand -->
    <FormRow v-if="!model.without_heating && enable_manual_rh_demand" :label="$t('manualRhDemandLabel')">
      <NumericInput v-model="model.manual_rh_demand" :min="0" :edit="isEditing" :units="'kWh/m²'" class="limit-width" />
    </FormRow>

    <!-- Year built -->
    <FormRow :label="$t('buildingYearLabel')" :info-text="$t('buildingYearInfoBox')">
      <NumericInput
        v-if="isEditing"
        v-model="model.building_year"
        noFormatting
        int
        optional
        :min="1400"
        :max="2100"
        :edit="isEditing"
        class="limit-width"
        @validation="onValidation($event, 'buildingYear')"
      />
      <SourcedValue
        v-else-if="envelopeResults"
        :value="envelopeResults.building_year.value"
        :translation-key="'generic.' + envelopeResults.building_year.source"
      />
      <span v-else-if="model.building_year">{{ model.building_year }}</span>
      <span v-else>{{ $t('unknownData') }}</span>
    </FormRow>

    <!-- Number of floors -->
    <FormRow :label="$t('floorsNumberLabel')" :info-text="$t('floorsNumberInfoBox')">
      <NumericInput
        v-if="isEditing"
        v-model="model.floors_number"
        int
        optional
        :min="1"
        :max="100"
        :edit="isEditing"
        class="limit-width"
        @validation="onValidation($event, 'floorNumber')"
      />
      <SourcedValue
        v-else-if="envelopeResults"
        :value="envelopeResults.floors_number.value"
        :translation-key="'generic.' + envelopeResults.floors_number.source"
      />
      <span v-else-if="model.floors_number">{{ model.floors_number }}</span>
      <span v-else>{{ $t('unknownData') }}</span>
    </FormRow>

    <!-- Building volume -->
    <FormRow v-if="isEditing || model.volume" :label="$t('volumeLabel')">
      <NumericInput
        v-model="model.volume"
        float
        optional
        :min="0"
        :edit="isEditing"
        :units="'m³'"
        class="limit-width"
        @validation="onValidation($event, 'volume')"
      />
    </FormRow>

    <!-- Roof type -->
    <FormRow :label="$t('roofTypeLabel')" :info-text="$t('roofTypeInfoBox')">
      <span v-if="isEditing">
        <v-select
          id="roof-type-options"
          v-model="model.roof_type"
          clearable
          :options="roofTypeOptions"
          :reduce="(t) => t.id"
          class="select-width"
        />
      </span>
      <SourcedValue
        v-else-if="envelopeResults"
        :value="roofTypeText"
        :translation-key="'roofType.' + envelopeResults.roof_type.source"
      />
      <span v-else>{{ $t('unknownData') }}</span>
    </FormRow>

    <!-- Attic / Dachgeschoss -->
    <FormRow
      v-if="model.roof_type === 'GABLE' && !model.without_heating && !enable_manual_rh_demand"
      :label="$t('atticLabel')"
    >
      <CheckInput
        v-model="model.attic_heated"
        :edit="isEditing"
        :label="$t('atticHeatedLabel')"
        :active-label="$t('atticHeatedLabel')"
        :inactive-label="$t('atticNotHeatedLabel')"
      />
    </FormRow>

    <!-- Basement / Kellerdecke -->
    <FormRow :label="$t('basementLabel')">
      <!-- Disabled if there is a planned measure with an envelope basement component -->
      <CheckInput
        v-model="model.basement_exists"
        :edit="isEditing"
        :label="$t('basementExistsLabel')"
        :active-label="$t('basementExistsLabel')"
        :inactive-label="$t('basementNotExistsLabel')"
        :disabled="hasManualEnvelopeBasementMeasures"
      />
    </FormRow>

    <!-- Building Components Table -->
    <DetailList v-if="!model.without_heating && !enable_manual_rh_demand" has-header :class="listClasses">
      <template #header>
        <span>{{ $t('componentTitle') }}</span>
        <span class="align-right">
          <span>{{ $t('yearTitle') }}</span>
          <InfoBox :title="$t('componentYearInfoBoxTitle')">{{ $t('componentYearInfoBox') }}</InfoBox>
        </span>
        <span class="align-right">
          <span>{{ $t('areaTitle') }}</span>
          <InfoBox :title="$t('componentAreaInfoBoxTitle')">{{ $t('componentAreaInfoBox') }}</InfoBox>
        </span>
        <span>{{ $t('insulationStandardTitle') }}</span>
        <span class="align-right">{{ $t('uvalueTitle') }}</span>
        <span class="align-right">
          <span>{{ $t('qtTitle') }}</span>
          <InfoBox :title="$t('qtInfoBoxTitle')">{{ $t('qtInfoBox') }}</InfoBox>
        </span>
        <span>{{ $t('remarksTitle') }}</span>
        <span class="align-right">
          <span>{{ $t('replacementYearTitle') }}</span>
          <InfoBox :title="$t('nextReplacementInfoBoxTitle')">{{ $t('nextReplacementInfoBox') }}</InfoBox>
        </span>
        <span class="align-right">
          <span>{{ $t('replacementCostsTitle') }}</span>
          <InfoBox :title="$t('replacementCostsInfoBoxTitle')">{{ $t('replacementCostsInfoBox') }}</InfoBox>
        </span>
      </template>

      <!--
        Facade / Walls
      -->
      <li>
        <span>{{ $t('wallsInsulationLabel') }}</span>
        <div class="align-right year-and-estimate">
          <NumericInput
            v-model="model.walls.year"
            noFormatting
            optional
            int
            :emptyValue="'' + building.walls_year"
            :min="1400"
            :max="2100"
            :edit="isEditing"
            @validation="onValidation($event, 'walls.year')"
          />
          <EstimateInput
            v-if="isEditing || model.walls.year_estimated"
            v-model="model.walls.year_estimated"
            :edit="isEditing"
            :tooltip="$t('componentStateEstimated')"
          />
          <template v-if="!isEditing && !model.walls.year && !model.walls.year_estimated && envelopeResults">
            <EstimateInput
              v-if="
                new Date().getFullYear() > building.walls_replacement_year &&
                building.walls_replacement_year + 5 - envelopeResults.building_year.value > 0
              "
              v-model="trueModel"
              :tooltip="$t('componentYearDerivedDue')"
            />
            <EstimateInput
              v-else-if="
                new Date().getFullYear() <= building.walls_replacement_year ||
                building.walls_replacement_year + 5 - envelopeResults.building_year.value <= 0
              "
              v-model="trueModel"
              :tooltip="$t('componentYearDerived')"
            />
          </template>
        </div>
        <div class="align-right">
          <NumericInput
            v-if="isEditing"
            v-model="model.walls.area"
            optional
            int
            :min="0"
            :edit="isEditing"
            :units="'m²'"
            @validation="onValidation($event, 'walls.area')"
          />
          <SourcedValue
            v-else-if="envelopeComponentResult('walls')"
            :value="formatNumber(envelopeComponentResult('walls').area.value) + ' m²'"
            :translation-key="'component.' + envelopeComponentResult('walls').area.source"
          />
          <span v-else-if="model.walls.area">{{ formatNumber(model.walls.area) }} m²</span>
          <span v-else>{{ $t('unknownData') }}</span>
        </div>
        <div>
          <v-select
            v-if="isEditing"
            id="walls-insulation-options"
            :key="selectedWallsStandard"
            v-model="model.walls.standard"
            :options="renovationStandardOptions"
            :clearable="false"
            :reduce="(t) => t.id"
            @input="onWallsInsulationChange"
          />
          <span v-else class="insulation-label">
            {{ selectedWallsLabel }}
            <EstimateInput
              v-if="!isEditing && renovationStandardOptions.filter((o) => o.id === model.walls.standard).length === 0"
              v-model="trueModel"
              :tooltip="$t('missingRenovationStandardTooltip')"
            />
          </span>
        </div>
        <div class="align-right">
          <div v-if="model.walls.standard === 'CUSTOM'">
            <NumericInput v-model="model.walls.uvalue" :min="0" :max="10" :edit="isEditing" :units="'W/m²K'" />
          </div>
          <div v-else-if="!isEditing && envelopeComponentResult('walls')">
            <span>{{ formatNumber(envelopeComponentResult('walls').uvalue.value, 2) }} W/m²K</span>
          </div>
        </div>
        <div class="align-right">
          <div v-if="envelopeComponentResult('walls')">
            {{ formatNumber(envelopeComponentResult('walls').Q_t / 1000, 1) }} MWh
          </div>
        </div>
        <div>
          <TextInput v-model="model.walls.remarks" :allow-empty="true" :edit="isEditing" />
        </div>
        <div class="align-right">
          <NumericInput
            v-model="model.walls.replacement_year"
            noFormatting
            optional
            int
            :min="1400"
            :max="2100"
            :edit="isEditing"
            @validation="onValidation($event, 'walls.replacement_year')"
          />
        </div>
        <div class="align-right">
          <NumericInput
            v-model="model.walls.replacement_costs"
            optional
            :units="'CHF'"
            :min="0"
            :edit="isEditing"
            @validation="onValidation($event, 'walls.replacement_costs')"
          />
        </div>
      </li>

      <!--
        Windows
      -->
      <li>
        <span>{{ $t('windowsInsulationLabel') }}</span>
        <div class="align-right year-and-estimate">
          <NumericInput
            v-model="model.windows.year"
            noFormatting
            optional
            int
            :emptyValue="'' + building.windows_year"
            :min="1400"
            :max="2100"
            :edit="isEditing"
            @validation="onValidation($event, 'windows.year')"
          />
          <EstimateInput
            v-if="isEditing || model.windows.year_estimated"
            v-model="model.windows.year_estimated"
            :edit="isEditing"
            :tooltip="$t('componentStateEstimated')"
          />
          <template v-if="!isEditing && !model.windows.year && !model.windows.year_estimated && envelopeResults">
            <EstimateInput
              v-if="
                new Date().getFullYear() > building.windows_replacement_year &&
                building.windows_replacement_year + 5 - envelopeResults.building_year.value > 0
              "
              v-model="trueModel"
              :tooltip="$t('componentYearDerivedDue')"
            />
            <EstimateInput
              v-else-if="
                new Date().getFullYear() <= building.windows_replacement_year ||
                building.windows_replacement_year + 5 - envelopeResults.building_year.value <= 0
              "
              v-model="trueModel"
              :tooltip="$t('componentYearDerived')"
            />
          </template>
        </div>
        <div class="align-right">
          <NumericInput
            v-if="isEditing"
            v-model="model.windows.area"
            optional
            int
            :min="0"
            :edit="isEditing"
            :units="'m²'"
            @validation="onValidation($event, 'windows.area')"
          />
          <SourcedValue
            v-else-if="envelopeComponentResult('windows')"
            :value="formatNumber(envelopeComponentResult('windows').area.value) + ' m²'"
            :translation-key="'component.' + envelopeComponentResult('windows').area.source"
          />
          <span v-else-if="model.windows.area">{{ formatNumber(model.windows.area) }} m²</span>
          <span v-else>{{ $t('unknownData') }}</span>
        </div>
        <div>
          <v-select
            v-if="isEditing"
            id="windows-insulation-options"
            :key="selectedWindowsStandard"
            v-model="model.windows.standard"
            :options="renovationStandardOptions"
            :clearable="false"
            :reduce="(t) => t.id"
            @input="onWindowsInsulationChange"
          />
          <span v-else class="insulation-label">
            {{ selectedWindowsLabel }}
            <EstimateInput
              v-if="!isEditing && renovationStandardOptions.filter((o) => o.id === model.windows.standard).length === 0"
              v-model="trueModel"
              :tooltip="$t('missingRenovationStandardTooltip')"
            />
          </span>
        </div>
        <div class="align-right">
          <div v-if="model.windows.standard === 'CUSTOM'">
            <NumericInput v-model="model.windows.uvalue" :min="0" :max="10" :edit="isEditing" :units="'W/m²K'" />
          </div>
          <div v-else-if="!isEditing && envelopeComponentResult('windows')">
            <span>{{ formatNumber(envelopeComponentResult('windows').uvalue.value, 2) }} W/m²K</span>
          </div>
        </div>
        <div class="align-right">
          <div v-if="envelopeComponentResult('windows')">
            {{ formatNumber(envelopeComponentResult('windows').Q_t / 1000, 1) }} MWh
          </div>
        </div>
        <div>
          <TextInput v-model="model.windows.remarks" :edit="isEditing" :allow-empty="true" />
        </div>
        <div class="align-right">
          <NumericInput
            v-model="model.windows.replacement_year"
            noFormatting
            optional
            int
            :min="1400"
            :max="2100"
            :edit="isEditing"
            @validation="onValidation($event, 'windows.replacement_year')"
          />
        </div>
        <div class="align-right">
          <NumericInput
            v-model="model.windows.replacement_costs"
            optional
            :units="'CHF'"
            :min="0"
            :edit="isEditing"
            @validation="onValidation($event, 'windows.replacement_costs')"
          />
        </div>
      </li>

      <!--
        Roof
      -->
      <li>
        <span v-if="model.roof_type === 'GABLE' && !model.attic_heated">{{ $t('atticInsulationLabel') }}</span>
        <span v-else>{{ $t('roofInsulationLabel') }}</span>
        <div class="align-right year-and-estimate">
          <NumericInput
            v-model="model.roof.year"
            noFormatting
            optional
            int
            :emptyValue="'' + building.roof_year"
            :min="1400"
            :max="2100"
            :edit="isEditing"
            @validation="onValidation($event, 'roof.year')"
          />
          <EstimateInput
            v-if="isEditing || model.roof.year_estimated"
            v-model="model.roof.year_estimated"
            :edit="isEditing"
            :tooltip="$t('componentStateEstimated')"
          />
          <template v-if="!isEditing && !model.roof.year && !model.roof.year_estimated && envelopeResults">
            <EstimateInput
              v-if="
                new Date().getFullYear() > building.roof_replacement_year &&
                building.roof_replacement_year + 5 - envelopeResults.building_year.value > 0
              "
              v-model="trueModel"
              :tooltip="$t('componentYearDerivedDue')"
            />
            <EstimateInput
              v-else-if="
                new Date().getFullYear() <= building.roof_replacement_year ||
                building.roof_replacement_year + 5 - envelopeResults.building_year.value <= 0
              "
              v-model="trueModel"
              :tooltip="$t('componentYearDerived')"
            />
          </template>
        </div>
        <div class="align-right">
          <NumericInput
            v-if="isEditing"
            v-model="model.roof.area"
            optional
            int
            :min="0"
            :edit="isEditing"
            :units="'m²'"
            @validation="onValidation($event, 'roof.area')"
          />
          <SourcedValue
            v-else-if="envelopeComponentResult('roof')"
            :value="formatNumber(envelopeComponentResult('roof').area.value) + ' m²'"
            :translation-key="'component.' + envelopeComponentResult('roof').area.source"
          />
          <span v-else-if="model.roof.area">{{ formatNumber(model.roof.area) }} m²</span>
          <span v-else>{{ $t('unknownData') }}</span>
        </div>
        <div>
          <v-select
            v-if="isEditing"
            id="roof-insulation-options"
            :key="selectedRoofStandard"
            v-model="model.roof.standard"
            :options="renovationStandardOptions"
            :clearable="false"
            :reduce="(t) => t.id"
            @input="onRoofInsulationChange"
          />
          <span v-else class="insulation-label">
            {{ selectedRoofLabel }}
            <EstimateInput
              v-if="!isEditing && renovationStandardOptions.filter((o) => o.id === model.roof.standard).length === 0"
              v-model="trueModel"
              :tooltip="$t('missingRenovationStandardTooltip')"
            />
          </span>
        </div>
        <div class="align-right">
          <div v-if="model.roof.standard === 'CUSTOM'">
            <NumericInput v-model="model.roof.uvalue" :min="0" :max="10" :edit="isEditing" :units="'W/m²K'" />
          </div>
          <div v-else-if="!isEditing && envelopeComponentResult('roof')">
            <span>{{ formatNumber(envelopeComponentResult('roof').uvalue.value, 2) }} W/m²K</span>
          </div>
        </div>
        <div class="align-right">
          <div v-if="envelopeComponentResult('roof')">
            {{ formatNumber(envelopeComponentResult('roof').Q_t / 1000, 1) }} MWh
          </div>
        </div>
        <div>
          <TextInput v-model="model.roof.remarks" :edit="isEditing" :allow-empty="true" />
        </div>
        <div class="align-right">
          <NumericInput
            v-model="model.roof.replacement_year"
            noFormatting
            optional
            int
            :min="1400"
            :max="2100"
            :edit="isEditing"
            @validation="onValidation($event, 'roof.replacement_year')"
          />
        </div>
        <div class="align-right">
          <NumericInput
            v-model="model.roof.replacement_costs"
            optional
            :units="'CHF'"
            :min="0"
            :edit="isEditing"
            @validation="onValidation($event, 'roof.replacement_costs')"
          />
        </div>
      </li>

      <!--
        Basement (Kellerdecke)
      -->
      <li v-if="model.basement_exists">
        <span>{{ $t('basementInsulationLabel') }}</span>
        <div class="align-right year-and-estimate">
          <NumericInput
            v-model="model.basement.year"
            noFormatting
            optional
            int
            :emptyValue="'' + building.basement_year"
            :min="1400"
            :max="2100"
            :edit="isEditing"
            @validation="onValidation($event, 'basement.year')"
          />
          <EstimateInput
            v-if="isEditing || model.basement.year_estimated"
            v-model="model.basement.year_estimated"
            :edit="isEditing"
            :tooltip="$t('componentStateEstimated')"
          />
          <template v-if="!isEditing && !model.basement.year && !model.basement.year_estimated && envelopeResults">
            <EstimateInput
              v-if="
                new Date().getFullYear() > building.basement_replacement_year &&
                building.basement_replacement_year + 5 - envelopeResults.building_year.value > 0
              "
              v-model="trueModel"
              :tooltip="$t('componentYearDerivedDue')"
            />
            <EstimateInput
              v-else-if="
                new Date().getFullYear() <= building.basement_replacement_year ||
                building.basement_replacement_year + 5 - envelopeResults.building_year.value <= 0
              "
              v-model="trueModel"
              :tooltip="$t('componentYearDerived')"
            />
          </template>
        </div>
        <div class="align-right">
          <NumericInput
            v-if="isEditing"
            v-model="model.basement.area"
            optional
            int
            :min="0"
            :edit="isEditing"
            :units="'m²'"
            @validation="onValidation($event, 'basement.area')"
          />
          <SourcedValue
            v-else-if="envelopeComponentResult('basement')"
            :value="formatNumber(envelopeComponentResult('basement').area.value) + ' m²'"
            :translation-key="'component.' + envelopeComponentResult('basement').area.source"
          />
          <span v-else-if="model.basement.area">{{ formatNumber(model.basement.area) }} m²</span>
          <span v-else>{{ $t('unknownData') }}</span>
        </div>
        <div>
          <v-select
            v-if="isEditing"
            id="basement-insulation-options"
            :key="selectedBasementStandard"
            v-model="model.basement.standard"
            :options="renovationStandardOptions"
            :clearable="false"
            :reduce="(t) => t.id"
            @input="onBasementInsulationChange"
          />
          <span v-else class="insulation-label">
            {{ selectedBasementLabel }}
            <EstimateInput
              v-if="
                !isEditing && renovationStandardOptions.filter((o) => o.id === model.basement.standard).length === 0
              "
              v-model="trueModel"
              :tooltip="$t('missingRenovationStandardTooltip')"
            />
          </span>
        </div>
        <div class="align-right">
          <div v-if="model.basement.standard === 'CUSTOM'">
            <NumericInput v-model="model.basement.uvalue" :min="0" :max="10" :edit="isEditing" :units="'W/m²K'" />
          </div>
          <div v-else-if="!isEditing && envelopeComponentResult('basement')">
            <span>{{ formatNumber(envelopeComponentResult('basement').uvalue.value, 2) }} W/m²K</span>
          </div>
        </div>
        <div class="align-right">
          <div v-if="envelopeComponentResult('basement')">
            {{ formatNumber(envelopeComponentResult('basement').Q_t / 1000, 1) }} MWh
          </div>
        </div>
        <div>
          <TextInput v-model="model.basement.remarks" :edit="isEditing" :allow-empty="true" />
        </div>
        <div class="align-right">
          <NumericInput
            v-model="model.basement.replacement_year"
            noFormatting
            optional
            int
            :min="1400"
            :max="2100"
            :edit="isEditing"
            @validation="onValidation($event, 'basement.replacement_year')"
          />
        </div>
        <div class="align-right">
          <NumericInput
            v-model="model.basement.replacement_costs"
            optional
            :units="'CHF'"
            :min="0"
            :edit="isEditing"
            @validation="onValidation($event, 'basement.replacement_costs')"
          />
        </div>
      </li>
    </DetailList>

    <!--
      Heritage Protection
    -->
    <div v-if="isEditing || model.heritage_protection !== 'UNKNOWN' || model.heritage_protection_remarks">
      <h3>{{ $t('heritageProtection') }}</h3>
      <FormRow :label="$t('heritageProtectionType')">
        <div v-if="isEditing">
          <v-select
            id="heritage-options"
            v-model="model.heritage_protection"
            :options="heritageProtectionOptions"
            :clearable="false"
            :reduce="(t) => t.id"
            class="select-width"
          />
        </div>
        <div v-else>
          {{ $t(`heritageProtectionOptions.${model.heritage_protection}`) }}
        </div>
      </FormRow>
      <FormRow :label="$t('heritageProtectionRemarks')">
        <TextInput v-model="model.heritage_protection_remarks" :edit="isEditing" :allow-empty="true" />
      </FormRow>
    </div>
  </EditContainer>
</template>

<script>
import { mapActions } from 'vuex'

import ApiFormMixin from '@/components/shared/forms/ApiFormMixin.vue'

import EditContainer from '@/components/shared/forms/EditContainer.vue'
import DetailList from '@/components/shared/lists/DetailList.vue'
import NumericInput from '@/components/shared/forms/NumericInput.vue'
import InfoBox from '@/components/shared/InfoBox.vue'
import TextInput from '@/components/shared/forms/TextInput.vue'
import CheckInput from '@/components/shared/forms/CheckInput.vue'
import FormRow from '@/components/shared/forms/FormRow.vue'
import EstimateInput from '@/components/shared/forms/EstimateInput.vue'
import SourcedValue from '@/components/building/edit/SourcedValue.vue'

export default {
  mixins: [
    ApiFormMixin,
    /* ApiFormMixin:
      - Requires implementation of the following methods: resetModel, saveHandler, isValid (optional)
      - Provides: model, isValid, isEditing, formState, onFormEvent, onValidation,
                  getBuildingPermission, getPortfolioPermission
    */
  ],
  components: {
    SourcedValue,
    EditContainer,
    NumericInput,
    InfoBox,
    TextInput,
    CheckInput,
    EstimateInput,
    FormRow,
    DetailList,
  },

  props: {
    building: {
      type: Object,
      required: true,
    },
    portfolio: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      enable_manual_rh_demand: null,
      roofTypeOptions: [
        { id: 'FLAT', label: this.$t('roofTypeFlat') },
        { id: 'GABLE', label: this.$t('roofTypeGable') },
      ],
      heritageProtectionOptions: [
        { id: 'UNKNOWN', label: this.$t('heritageProtectionOptions.UNKNOWN') },
        { id: 'NONE', label: this.$t('heritageProtectionOptions.NONE') },
        { id: 'REGION', label: this.$t('heritageProtectionOptions.REGION') },
        { id: 'CANTON', label: this.$t('heritageProtectionOptions.CANTON') },
      ],
      selectedBasementStandard: '',
      selectedRoofStandard: '',
      selectedWallsStandard: '',
      selectedWindowsStandard: '',
    }
  },

  computed: {
    envelopeResults() {
      return this.building.initial_state.result && this.building.initial_state.result.envelope
    },

    renovationStandardOptions() {
      const options = [
        { id: 'AUTO', label: this.$t('typicalInsulation') },
        { id: 'CUSTOM', label: this.$t('customInsulation') },
      ]
      this.building.renovation_standards.forEach((rs) =>
        options.push({
          id: rs.name,
          label: rs.name, // TOOD: rs.id for id
        })
      )
      this.portfolio.renovation_standards
        .filter((rs) => !rs.building_id && options.filter((o) => o.id === rs.name).length === 0)
        .forEach((rs) =>
          options.push({
            id: rs.name,
            label: rs.name, // TOOD: rs.id for id
          })
        )
      return options
    },

    hasManualEnvelopeMeasures() {
      return (
        this.building.measures
          .filter((m) => m.actions.envelope !== undefined)
          .filter((m) => m.actions.envelope !== null)
          .filter((m) => m.phase !== 'SCENARIO').length !== 0
      )
    },

    hasManualEnvelopeBasementMeasures() {
      return (
        this.building.measures
          .filter((m) => m.actions.envelope !== undefined)
          .filter((m) => m.actions.envelope !== null)
          .filter((m) => m.phase !== 'SCENARIO')
          .filter((m) => m.actions.envelope.basement !== undefined)
          .filter((m) => m.actions.envelope.basement !== null).length !== 0
      )
    },

    roofTypeText() {
      if (this.envelopeResults && this.envelopeResults.roof_type.value) {
        const type = this.roofTypeOptions.find((o) => o.id === this.envelopeResults.roof_type.value)
        return type.label
      } else {
        return this.$t('unknownData')
      }
    },

    listClasses() {
      return {
        edit: this.edit,
      }
    },

    selectedRoofLabel() {
      const option = this.renovationStandardOptions.find((o) => o.id === this.model.roof.standard)
      return (option && option.label) || this.model.roof.standard
    },

    selectedWindowsLabel() {
      const option = this.renovationStandardOptions.find((o) => o.id === this.model.windows.standard)
      return (option && option.label) || this.model.windows.standard
    },

    selectedBasementLabel() {
      const option = this.renovationStandardOptions.find((o) => o.id === this.model.basement.standard)
      return (option && option.label) || this.model.basement.standard
    },

    selectedWallsLabel() {
      const option = this.renovationStandardOptions.find((o) => o.id === this.model.walls.standard)
      return (option && option.label) || this.model.walls.standard
    },

    trueModel() {
      return true // TODO: obviously get rid of this
    },
  },

  watch: {
    enable_manual_rh_demand() {
      if (!this.enable_manual_rh_demand) {
        this.model.manual_rh_demand = null
      }
    },

    building() {
      this.resetModel()
    },

    isEditing() {
      this.resetModel()
    },
  },

  mounted() {
    this.enable_manual_rh_demand = this.model.manual_rh_demand !== null && this.model.manual_rh_demand !== undefined
  },

  methods: {
    ...mapActions({
      updateBuilding: 'building/updateBuilding',
    }),

    envelopeComponentResult(componentType) {
      return this.envelopeResults && this.building.initial_state.result.envelope[componentType]
    },

    resetModel() {
      this.model = {
        building_year: this.building.initial_state.envelope.building_year,
        floors_number: this.building.initial_state.envelope.floors_number,
        roof_type: this.building.initial_state.envelope.roof_type,
        attic_heated: this.building.initial_state.envelope.attic_heated,
        basement_exists: this.building.initial_state.envelope.basement_exists,
        remarks: this.building.initial_state.envelope.remarks,
        without_heating: this.building.initial_state.envelope.without_heating,
        manual_rh_demand: this.building.initial_state.envelope.manual_rh_demand,
        heritage_protection: this.building.initial_state.envelope.heritage_protection,
        heritage_protection_remarks: this.building.initial_state.envelope.heritage_protection_remarks,
        volume: this.building.initial_state.envelope.volume,
      }
      for (const c of ['walls', 'windows', 'roof', 'basement']) {
        this.model[c] = {
          year: this.building.initial_state.envelope[c].year,
          year_estimated: this.building.initial_state.envelope[c].year_estimated,
          replacement_year: this.building.initial_state.envelope[c].replacement_year,
          replacement_costs: this.building.initial_state.envelope[c].replacement_costs,
          area: this.building.initial_state.envelope[c].area,
          standard: this.building.initial_state.envelope[c].standard || 'CUSTOM',
          uvalue:
            this.building.initial_state.envelope[c].uvalue !== null
              ? this.building.initial_state.envelope[c].uvalue.toFixed(2)
              : null,
          remarks: this.building.initial_state.envelope[c].remarks,
        }
      }
    },

    async saveHandler(model) {
      await this.updateBuilding({
        id: this.building.id,
        initial_state: {
          scenario_id: this.building.initial_state.scenario_id,
          envelope: model,
        },
      })
    },

    onBasementInsulationChange(value) {
      this.selectedBasementStandard = value
    },

    onRoofInsulationChange(value) {
      this.selectedRoofStandard = value
    },

    onWindowsInsulationChange(value) {
      this.selectedWindowsStandard = value
    },

    onWallsInsulationChange(value) {
      this.selectedWallsStandard = value
    },
  },
}
</script>

<style lang="scss">
.c-edit-envelope {
  .insulation-label {
    margin-right: var(--spacing-l);
  }

  & .detail-list > ul > li {
    display: grid;
    grid-template-columns: 1fr 110px 90px 1fr 120px 120px 1fr 75px 100px;
  }

  & .limit-width {
    width: 240px;
  }

  & .select-width {
    display: inline-block;
    width: 240px;
  }

  & .year-and-estimate {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--spacing-xs);
  }

  & .align-right {
    text-align: right;
    justify-content: flex-end;
  }
}

.space-before {
  margin-left: 0.3rem;
}
</style>
