<i18n>
{
  "de": {
    "pvProfitabilityTitle": "Variantenvergleich Photovoltaik",
    "capacityLabel": "Installierte Kapazität",
    "investmentLabel": "Investitionskosten",
    "yearsLabel": "Jahre",
    "npvLabel": "Barwert (NPV)",
    "npvChartLabel": "Entwicklung Barwert",
    "yieldRateLabel": "Rendite",
    "breakEvenLabel": "Rückzahldauer",
    "lcoeLabel": "Stromgestehungskosten",
    "subsidiesLabel": "Einmalvergütung",
    "productionLabel": "Produzierte Energie",
    "ownUseLabel": "Eigenverbrauch",
    "demandLabel": "Strombedarf (Deckungsgrad)",
    "gridTarifs": "Rückliefertarif / Bezugstarif H4",
    "nRoofModulesLabel": "Dachmodule",
    "nFassadeModulesLabel": "Fassadenmodule",
    "interestRateLabel": "Zinssatz",
    "amortizationDurationLabel": "Lebensdauer",
    "priceInflationLabel": "Strompreisteuerung",
    "operationCostsLabel": "Betriebskosten",
    "noPvMeasuresMessage": "Keine PV-Massnahmen vorhanden",
    "comparabilityInfo": "Um die Vergleichbarkeit mit der Berechnung von sonnendach.ch zu gewährleisten, wird ebenfalls mit einem Modulwirkungsgrad von 20% gerechnet und auf die Berücksichtigung der Degradation verzichtet."
  }
}
</i18n>
<template>
  <div class="c-pv-overview">
    <Card collapsible section="pvComparison">
      <!-- Header -->
      <CardHeader slot="header" :title="$t('pvProfitabilityTitle')" :icon="'cards/pv-comparison'" />

      <!-- List -->
      <CardContent slot="content">
        <div v-if="measures.length > 0" class="pv-measures">
          <DetailList has-header>
            <template #header>
              <div class="sticky-column"></div>
              <div v-for="measure in measures" :key="measure.id" class="pv-measure">{{ measureTitle(measure) }}</div>
            </template>

            <li>
              <div class="sticky-column"></div>
              <div v-for="measure in measures" :key="measure.id" class="pv-measure">
                <PvMiniMap v-if="building.roof.state === 'OK'" :building="building" :measure="measure" />
              </div>
            </li>

            <li>
              <div class="sticky-column">{{ $t('capacityLabel') }} <sup>(1)</sup></div>
              <div v-for="measure in measures" :key="measure.id" class="pv-measure">
                {{ getCapacityWithModules(measure) }}
              </div>
            </li>
            <li>
              <div class="sticky-column">{{ $t('productionLabel') }}</div>
              <div v-for="measure in measures" :key="measure.id" class="pv-measure">
                {{ formatNumber(kpi(measure).el_production / 1000, 0) }} MWh ({{
                  formatNumber(kpi(measure).el_production / totalCapacity(measure), 0)
                }}
                kWh/kWp)
              </div>
            </li>
            <li>
              <div class="sticky-column">{{ $t('demandLabel') }}</div>
              <div v-for="measure in measures" :key="measure.id" class="pv-measure">
                {{ formatNumber(kpi(measure).el_demand / 1000, 0) }} MWh ({{
                  formatNumber((kpi(measure).el_production / kpi(measure).el_demand) * 100, 0)
                }}
                %)
              </div>
            </li>
            <li>
              <div class="sticky-column">{{ $t('ownUseLabel') }}</div>
              <div v-for="measure in measures" :key="measure.id" class="pv-measure">
                <span v-if="npvDetails(measure)">
                  {{ formatNumber((kpi(measure).el_ownuse / kpi(measure).el_production) * 100, 0) }} %
                </span>
              </div>
            </li>
            <li>
              <div class="sticky-column">{{ $t('investmentLabel') }}</div>
              <div v-for="measure in measures" :key="measure.id" class="pv-measure">
                {{ formatNumber(costs(measure).pv.costs, -2) }} CHF
              </div>
            </li>
            <li>
              <div class="sticky-column">{{ $t('subsidiesLabel') }}</div>
              <div v-for="measure in measures" :key="measure.id" class="pv-measure">
                {{ formatNumber(costs(measure).pv.subsidies) }} CHF
              </div>
            </li>
            <li>
              <div class="sticky-column">{{ $t('gridTarifs') }}</div>
              <div v-for="measure in measures" :key="measure.id" class="pv-measure">
                {{ formatNumber(npvDetails(measure).feedin_tarif * 100, 1) }} Rp/kWh /
                {{ formatNumber(npvDetails(measure).grid_tarif * 100, 1) }} Rp/kWh
              </div>
            </li>
            <li>
              <div class="sticky-column">{{ $t('lcoeLabel') }} <sup>(2,3,4)</sup></div>
              <div v-for="measure in measures" :key="measure.id" class="pv-measure">
                {{ formatNumber(pvLcoe(measure), 2) }} CHF/kWh
              </div>
            </li>
            <li>
              <div class="sticky-column">{{ $t('yieldRateLabel') }} <sup>(3,4,5)</sup></div>
              <div v-for="measure in measures" :key="measure.id" class="pv-measure">
                {{ formatNumber(yieldRate(measure), 1) }} %
              </div>
            </li>
            <li>
              <div class="sticky-column">{{ $t('breakEvenLabel') }} <sup>(2,4,5)</sup></div>
              <div v-for="measure in measures" :key="measure.id" class="pv-measure">
                <span v-if="breakEven(measure) !== null">{{ breakEven(measure) }} {{ $t('yearsLabel') }}</span>
                <span v-else>-</span>
              </div>
            </li>
            <li>
              <div class="sticky-column">{{ $t('npvLabel') }} <sup>(2,3,4,5)</sup></div>
              <div v-for="measure in measures" :key="measure.id" class="pv-measure">
                {{ formatNumber(npv(measure), 0) }} CHF
              </div>
            </li>

            <li>
              <div class="sticky-column">{{ $t('npvChartLabel') }}</div>
              <div v-for="measure in measures" :key="measure.id" class="pv-measure">
                <NpvChart v-if="npvDetails(measure)" :data="npvData(measure)" />
              </div>
            </li>
          </DetailList>
        </div>

        <NoDataMessage v-else :message="$t('noPvMeasuresMessage')" />

        <div v-if="measures.length > 0" class="footnotes">
          <sup>(1)</sup> {{ $t('comparabilityInfo') }}<br />
          <sup>(2)</sup> {{ `${$t('interestRateLabel')}: ${portfolio.interest_rate} %` }}<br />
          <sup>(3)</sup> {{ `${$t('amortizationDurationLabel')}: ${amortizationDuration()} ${$t('yearsLabel')}` }}<br />
          <sup>(4)</sup> {{ `${$t('operationCostsLabel')}: ${portfolio.operation_costs.pv} CHF/kWh` }}<br />
          <sup>(5)</sup> {{ `${$t('priceInflationLabel')}: ${portfolio.energy_prices.electricity.inflation} %` }}
        </div>
      </CardContent>
    </Card>
  </div>
</template>

<script>
import DetailList from '@/components/shared/lists/DetailList.vue'
import PvMiniMap from '@/components/building/map/PvMiniMap.vue'
import NpvChart from '@/components/building/measures/NpvChart.vue'
import Card from '@/components/cui/surfaces/Card.vue'
import CardHeader from '@/components/cui/surfaces/CardHeader.vue'
import CardContent from '@/components/cui/surfaces/CardContent.vue'
import NoDataMessage from '@/components/shared/lists/NoDataMessage.vue'

export default {
  components: {
    DetailList,
    NpvChart,
    PvMiniMap,
    Card,
    CardHeader,
    CardContent,
    NoDataMessage,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
    building: {
      type: Object,
      required: true,
    },
  },

  computed: {
    measures() {
      const scenarios = this.portfolio.scenarios.map((s) => s.id)
      const measures = []
      // Use the order of the scenarios, check each measure for the scenario id match, and then add it to the list of pv measures
      if (scenarios) {
        scenarios.map((s) => {
          this.building.measures.forEach((m) => {
            if (m.scenario_id === s && m.actions['pv'] !== null && this.npv(m)) {
              measures.push(m)
            }
          })
        })
      }
      return measures
    },
  },
  methods: {
    pvSections(measure) {
      return measure.results[measure.scenario_id].pv_sections
    },

    npvDetails(measure) {
      return measure.results[measure.scenario_id].pv_npv_details
    },

    npv(measure) {
      return measure.results[measure.scenario_id].pv_npv
    },

    kpi(measure) {
      return measure.results[measure.scenario_id].kpi
    },

    costs(measure) {
      const state = measure.states.find((s) => measure.scenario_id === s.scenario_id)
      return state?.result.costs
    },

    pvLcoe(measure) {
      return measure.results[measure.scenario_id].pv_lcoe
    },

    yieldRate(measure) {
      return measure.results[measure.scenario_id].pv_yield_rate
    },

    breakEven(measure) {
      return measure.results[measure.scenario_id].pv_break_even
    },

    npvData(measure) {
      return {
        cashflow: this.npvDetails(measure).cashflow_pv,
        npv: this.npvDetails(measure).cashflow_pv_cumulative,
        years: this.npvDetails(measure).cashflow_pv_cumulative.map((v, idx) => idx),
      }
    },

    // for portfolios not yet simulated after changing lifespans.pv
    amortizationDuration() {
      if (this.measures.length > 0) {
        return this.npvDetails(this.measures[0]).cashflow_pv_cumulative.length - 1
      } else {
        return this.portfolio.lifespans.pv
      }
    },

    measureTitle(measure) {
      if (!this.portfolio) {
        return null
      }
      if (measure.scenario_id === null) {
        if (measure.remarks) {
          return measure.remarks
        } else {
          return null
        }
      } else {
        const scenario = this.portfolio.scenarios.find((s) => s.id === measure.scenario_id)
        return scenario.description
      }
    },

    totalCapacity(measure) {
      return this.pvSections(measure).reduce((pv, cv) => pv + cv.capacity, 0)
    },

    totalRoofModules(measure) {
      return this.pvSections(measure).reduce((pv, cv) => pv + (!cv.fassade_tile_id ? cv.n_modules : 0), 0)
    },

    totalFassadeModules(measure) {
      return this.pvSections(measure).reduce((pv, cv) => pv + (cv.fassade_tile_id ? cv.n_modules : 0), 0)
    },

    getCapacityWithModules(measure) {
      const totalRoof = this.totalRoofModules(measure)
      const totalFacade = this.totalFassadeModules(measure)
      const roof = `${totalRoof} ${this.$t('nRoofModulesLabel')}`
      const optionalFacade = totalFacade ? ` + ${totalFacade} ${this.$t('nFassadeModulesLabel')}` : ''
      return `${this.formatNumber(this.totalCapacity(measure), 1)} kWp (${roof}${optionalFacade})`
    },
  },
}
</script>

<style lang="scss">
.c-pv-overview {
  & .pv-measures {
    border-bottom: var(--box-border);
    overflow: hidden;
    width: 100%;
    max-width: calc(100vw - 2 * var(--spacing-m) - 2px);

    & .detail-list {
      overflow: hidden;
      width: 100%;

      & ul {
        max-height: 70svh;
        overflow: auto;
        border: none;
        width: 100%;

        & li {
          min-width: fit-content;
          width: 100%;
          align-items: stretch;
          padding: 0 10px 0 0 !important;

          & .pv-measure {
            overflow: hidden;
            padding: 10px 0;
          }
        }
      }
    }

    & .pv-minimap {
      height: 360px;
    }

    & .npv-chart {
      height: 400px;
    }

    & li {
      & > * {
        width: 100%;
        min-width: 400px;
      }

      & > *:first-child {
        min-width: 300px;
      }
    }
  }

  & .footnotes {
    padding: var(--spacing-s);
  }

  & sup {
    font-size: 11px;
  }

  & .no-data-message {
    padding: var(--spacing-s);
    text-align: center;
  }
}
</style>
